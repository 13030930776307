import * as React from 'react'
import { Link } from 'gatsby'

import { PageLayout } from '../components/page-layout'

const NotFoundPage: React.FC = () => {
  return (
    <PageLayout title='Not found'>
      <h1>Page not found</h1>
      <p><Link to='/'>Go home</Link></p>
    </PageLayout>
  )
}

export default NotFoundPage
